<template>
  <div>
    <span class="label">
      {{ label }}
    </span>
    <el-select
      :value="value"
      filterable
      clearable
      :placeholder="placeholder"
      value-key="guid"
      size="mini"
      :disabled="disabled"
      :loading="loading"
      @clear="$emit('input', null)"
      @change="$emit('input', $event)"
      @visible-change="onOpen">
      <el-option
        v-for="item in options"
        :key="item.guid"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'SelectField',
  inject: ['getPreviousValue'],

  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String
    },
    value: {
      type: Object
    }
  },

  data () {
    return {
      loading: false,
      options: [],
      isLoaded: false
    }
  },

  computed: {
    disabled () {
      return !((this.type === 'subject') || this.previousValue)
    },
    previousValue () {
      return this.getPreviousValue(this.type)
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (value && this.options.findIndex((item) => item.guid === value.guid) === -1) {
          this.options.push(value)
        }
      }
    },
    previousValue () {
      this.isLoaded = false
      this.$emit('input', null)
    }
  },

  methods: {
    onOpen (isShowing) {
      let event = isShowing ? 'open-popup' : 'close-popup'
      this.$emit(event)
      if (isShowing && !this.isLoaded) {
        this.load()
      }
    },
    async load () {
      this.loading = true
      let options = await this.$http.get(`${this.$config.api}/fiasaddressservice/fias/${this.type}${this.type === 'subject' ? '' : `/${this.previousValue.guid}`}`)
      this.options = options.data
      this.isLoaded = true
      this.loading = false
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
